.navbar {
  height: 65px;
  width: 100%;
  padding: 0 !important;
}
.navLogo {
  margin-left: 15px;
}

@media (max-width: 768px) {
  .navLinkler {
    display: none !important;
  }
  .navLogo {
    width: 110px;
    position: absolute;
    top: 0;
    right: 10px;
  }
  .navbar {
    height: 40px;
  }
}
