.footer {
  background-color: white;
  width: 100%;
  height: 60px;
  position: absolute;
  bottom: 0;
  height: fit-content;
}

.footer ul {
  padding-top: 7px;
  width: 100%;
  display: flex;
  list-style: none;
  margin-bottom: 0;
  justify-content: center;
}

.footer ul li {
  margin: 0% 2.5%;
}

.footerInfo {
  /* padding-top: 10px; */
  font-size: 12px;
}
.footerBorder {
  border-right: 1px gray solid;
  padding-right: 50px;
}

.footerSocials {
  padding-top: 10px;
  margin: 0;
  display: flex;
}

.footerKK {
  padding-left: 10%;
  padding-top: 1%;
  width: 200px;

  font-style: italic;
  font-size: 0.8rem !important;
}

@media (max-width: 768px) {
  .footer ul {
    padding-left: 0;
  }

  .footerLogo {
    padding-right: 10px;
  }

  .footerInfo {
    font-size: 20%;
  }
  .footerKK {
    display: none;
  }

  .footerSocials img {
    width: 130%;
  }
  .footerSocials {
    padding-left: 50px;
  }
  .izinsiz {
    display: none;
  }

  .footer ul li {
    display: block;
    padding: 0;
  }

  .footerBorder {
    width: 10%;
  }
  .footerBorder {
    border-right: 0 !important;
  }
}
