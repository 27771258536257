.ikonlar {
  display: flex;
  justify-content: space-around;
}

@media (max-width: 768px) {
  .ikonlar span {
    display: none;
  }
}
