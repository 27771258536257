* {
  font-family: Roboto;
}

html,
body {
  margin: 0;
  /* overflow-x: hidden;  scrollToTop'i engelliyor */
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    123.5deg,
    rgba(244, 219, 251, 1) 29.3%,
    rgba(255, 214, 214, 1) 67.1%
  );
  background-repeat: no-repeat;
  background-attachment: fixed;
}

#root {
  min-height: 100%;
  position: relative;
  padding-bottom: 60px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
