.dergiler {
  margin-top: 20px;
  margin-bottom: 50px;
}

@media (max-width: 768px) {
  .dergiler {
    margin-left: 55px;
    margin-bottom: 120px;
  }
}
