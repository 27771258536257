@media (max-width: 768px) {
  .carousel {
    height: 150% !important;

    position: absolute;
    top: 0px;
  }
  .carousel-caption {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
}

.carousel {
  margin-bottom: 20px;
}
